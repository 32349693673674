import ApiService from './ApiService'

export const apiLogIn = (data) => ApiService.post('/userauth/login/', data)
export const apiSignUp = (data) =>
    ApiService.filePost('/company/company-registration/', data)
export const apiSignOut = () => ApiService.post('/userauth/logout/')
export const apiChangePassword = (data) =>
    ApiService.post('/userauth/change-password/', data)
export const apiForgotPassword = (data) =>
    ApiService.post('/userauth/password-reset/', data)
export const apiResetPassword = (data) =>
    ApiService.post('/userauth/set-password/', data)
export const getApiProfile = (id) => ApiService.get(`/userauth/user-profile/`)
export const updateApiProfile = (data) =>
    ApiService.filePut(`/userauth/user-profile/`, data)
