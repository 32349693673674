import React from 'react'
import DoubleSidedImage from './DoubleSidedImage'
import { isEmpty } from 'lodash'
import Loading from './Loading'

const EmptyData = (props) => {
    const { loading, data, children } = props

    return (
        <>
            <Loading loading={loading}>{!isEmpty(data) && children}</Loading>
            {!loading && isEmpty(data) && (
                <div className="flex flex-col items-center justify-center h-full">
                    <DoubleSidedImage
                        src="/img/others/img-2.png"
                        darkModeSrc="/img/others/img-2-dark.png"
                        alt="No data found!"
                    />
                    <h3 className="mt-8">No data found!</h3>
                </div>
            )}
        </>
    )
}

export default EmptyData
