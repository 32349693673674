const InfoField = ({ grid = true, title, value }) => {
    return (
        <div className={`${grid ? 'grid grid-cols-2 gap-4' : ''}`}>
            <span className="font-semibold text-gray-700 dark:text-gray-200">
                {title}
            </span>
            <p>{value}</p>
        </div>
    )
}

export default InfoField
